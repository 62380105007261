<template>
	<div class="welfare">
		<div class="welfare-banner">
			<h1 class="title">{{ $t("tabbar.welfare") }}</h1>
		</div>
		<div class="qi-btn__container">
			<div class="qi-btn">
				<span class="qi-text">{{ $t("index.period_time") }}</span>
				<span>{{ config.issue_num.value }}</span>
			</div>
		</div>
		<div class="time">
			<div class="welfare-balance-pc">
				<img class="icon" src="../../assets/icon-gold.png" alt="" />
				{{ $t("user.balance") }}：<span>{{
					(user.balance || "0.00") + $t("币单位")
				}}</span>
			</div>

			<div>
				<span>{{ $t("index.deadline_participation") }}：</span>
				<van-count-down
					:time="betTime"
					@change="betTimeChange"
					v-if="time > 0"
				/>
				<span class="fc-red" v-else>{{ $t("index.closed") }}</span>
			</div>
			<div>
				<span>{{ $t("index.deadline") }}：</span>
				<van-count-down :time="openTime" @finish="openFinish" />
			</div>
		</div>
		<div class="main">
			<div class="welfare-balance">
				<img class="icon" src="../../assets/icon-gold.png" alt="" />
				{{ $t("user.balance") }}：<span>{{
					(user.balance || "0.00") + $t("币单位")
				}}</span>
			</div>

			<div class="welfare-card__container">
				<div
					class="welfare-card card"
					v-for="(item, index) in list"
					:key="item.id"
				>
					<div class="welfare-head">
						<div class="flex align-items__center">
							<span class="welfare-index">{{
								index >= 9 ? index + 1 : `0${index + 1}`
							}}</span>
							<span
								>{{ $t("index.min_bet_amount") }}：{{ item.min_amount }}</span
							>
						</div>
						<div class="welfare-head-time">{{ item.updated_at }}</div>
					</div>
					<div class="welfare-btns">
						<div class="btns-left-pc" @click="tapItem('red', index, item)">
							<div class="van-ellipsis fc-red">
								{{ item[`red_${lang}_title`] }}
							</div>
							<div class="welfare-btn" style="left: 10px">
								{{ $t("选红色") }}
							</div>
							<img class="pk-left" src="../../assets/pk-left-new.png" alt="" />
						</div>
						<img
							class="welfare-btns__pk"
							src="../../assets/pk-new.png"
							alt=""
						/>
						<img
							class="welfare-btns__pk-pc"
							src="../../assets/pk-pc.png"
							alt=""
						/>
						<div class="btns-right-pc" @click="tapItem('blue', index, item)">
							<div class="welfare-btn" style="right: 10px">
								{{ $t("选蓝色") }}
							</div>
							<img
								class="pk-right"
								src="../../assets/pk-right-new.png"
								alt=""
							/>
							<div class="van-ellipsis fc-blue">
								{{ item[`blue_${lang}_title`] }}
							</div>
						</div>
					</div>
					<div class="welfare-content">
						<div class="van-ellipsis fc-red">
							{{ item[`red_${lang}_title`] }}
						</div>
						<div class="van-ellipsis fc-blue">
							{{ item[`blue_${lang}_title`] }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<van-popup
			v-model="showPw"
			class="password"
			round
			style="width: 85%; background: #242229"
		>
			<div class="popup-main">
				<h3 class="popup-title">{{ $t("login.tip2") }}</h3>
				<van-form class="form-box" ref="pwForm">
					<van-field
						class="input-pw"
						v-model="password"
						type="password"
						name="password"
						:placeholder="$t('密码')"
						:rules="[
							{ required: true, trigger: 'onBlur', message: $t('login.tip2') },
						]"
					/>
					<div class="btn-box">
						<van-button
							class="btn"
							round
							block
							size="small"
							type="default"
							@click="closePw"
							native-type="button"
							>{{ $t("取消") }}</van-button
						>
						<van-button
							class="btn"
							round
							block
							size="small"
							type="info"
							:loading="loadingPw"
							native-type="button"
							@click="onSubmit"
							>{{ $t("确认") }}</van-button
						>
					</div>
				</van-form>
			</div>
		</van-popup>
		<van-popup v-model="show" class="Bet" style="width: 90%; height: 70%">
			<img class="icon-pk" src="../../assets/pk-new.png" alt="" />
			<div class="popup-container">
				<div class="popup-top">
					<div class="popup-top__text">
						{{ index >= 9 ? index + 1 : `0${index + 1}`
						}}{{ $t("index.number_one") }}
					</div>
					<img class="popup-top__bg" src="../../assets/popup_bg.png" alt="" />
				</div>
				<div class="popup-top__btn">
					<van-button
						@click="red = !red"
						:color="red ? '#ee0a24' : '#2E3036'"
						size="small"
						style="width: 98px; border-radius: 7px; margin-right: 24px"
						>{{ $t("index.red_team") }}
					</van-button>
					<van-button
						@click="blue = !blue"
						:color="blue ? '#1989fa' : '#2E3036'"
						size="small"
						style="width: 98px; border-radius: 7px"
					>
						{{ $t("index.blue_team") }}
					</van-button>
				</div>
				<div class="popup-info">
					<p>{{ $t("user.balance") }}：{{ user.balance + $t("币单位") }}</p>
				</div>
				<div class="block-pl">
					<div class="pl-item left">
						<span>{{ red ? item.red_odd : "-" }}</span>
						<p>{{ $t("index.red_team_odds") }}</p>
					</div>
					<hr class="hr" />
					<div class="pl-item right">
						<span>{{ blue ? item.blue_odd : "-" }}</span>
						<p>{{ $t("index.blue_team_odds") }}</p>
					</div>
				</div>
				<div class="welfare-stepper">
					<van-stepper
						v-model="chips"
						input-width="139px"
						button-size="38px"
						step="100"
						min="0"
					/>
				</div>
				<div class="welfare-ships__btn">
					<van-button
						type="default"
						color="#2E3036"
						size="small"
						@click="chips = 15000"
						>15000</van-button
					>
					<van-button
						type="default"
						color="#2E3036"
						size="small"
						@click="chips = 50000"
						style="margin: 0 17px"
						>50000</van-button
					>
					<van-button
						type="default"
						color="#2E3036"
						size="small"
						@click="chips = user.balance"
						>{{ $t("index.all") }}</van-button
					>
				</div>

				<div class="px-24" style="margin: 24px auto">
					<van-button
						class="submit-btn"
						color="linear-gradient( 270deg, #A44AFB 0%, #F85DC6 100%)"
						block
						round
						@click="_betCreate"
						:loading="loading"
					>
						{{ $t("index.get_involved_now") }}
					</van-button>
				</div>
			</div>
		</van-popup>
		<Tabbar :key="lang" :selectTab="1" />
	</div>
</template>

<script>
import Tabbar from "@/components/Tabbar/Tabbar.vue";
import { quizList, betCreate, verifyPass } from "@/api";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
	components: {
		Tabbar,
	},
	data() {
		return {
			time: "",
			show: false,
			red: false,
			blue: false,
			chips: "",
			redOdd: "0.00",
			blueOdd: "0.00",
			balance: 0,
			list: [],
			index: "",
			item: {
				red_odd: "0.00",
				blue_odd: "0.00",
			},
			loading: false,
			showPw: false,
			password: "",
			loadingPw: false,
		};
	},
	created() {
		this._quizList();
		this.fetchUser();
		this.fetchConfig();

		window.Echo.channel("lottery-drawing").listen(".LotteryDrawing", (e) => {
			console.log(e);
			this.$set(this.config, "lottery_time", { value: e.data.lottery_time });
			this.$set(this.config, "issue_num", { value: e.data.issue_num });
			this._quizList();
			this.fetchUser();
		});

		// window.Echo.channel('public')
		//     .listen('.DemoEvent', (e) => {
		//       console.log(e);
		//     });
	},
	computed: {
		...mapState(["user", "config", "lang"]),
		betTime() {
			return this.openTime - 30 * 1000;
		},
		openTime() {
			// const now = moment().utc();
			// const lotteryTime = moment.utc(this.config.lottery_time.value);
			// return lotteryTime.diff(now, 'milliseconds');

			const now = moment();
			const lotteryTime = moment(this.config.lottery_time.value);
			return lotteryTime.diff(now, "milliseconds");
		},
	},
	destroyed() {
		window.Echo.leave("lottery-drawing");
	},
	methods: {
		...mapActions(["fetchUser", "fetchConfig"]),
		betTimeChange(time) {
			this.time = time.milliseconds;
		},
		openFinish() {
			this.fetchUser();
			this.fetchConfig();
		},
		tapItem(type, index, item) {
			this.item = item;
			this.index = index;
			if (item.password) {
				this.showPw = true;
			} else {
				this.show = true;
			}
			if (type === "red") {
				this.red = true;
			}
			if (type === "blue") {
				this.blue = true;
			}
		},
		onSubmit() {
			//输入密码提交
			this.$refs.pwForm.validate().then(() => {
				this.loadingPw = true;
				verifyPass({ id: this.item.id, password: this.password })
					.then((res) => {
						this.loadingPw = false;
						if (res.code == 200) {
							this.show = true;
							this.closePw();
						} else {
							this.$toast(this.$t("密码错误"));
						}
					})
					.catch(() => {
						this.loadingPw = false;
						this.$toast(this.$t("密码错误"));
					});
			});
		},
		closePw() {
			this.password = "";
			this.$refs.pwForm.resetValidation("password");
			this.showPw = false;
		},
		_quizList() {
			quizList().then(({ data }) => {
				this.list = data.data;
			});
		},
		_betCreate() {
			if (!this.chips) {
				this.$toast(this.$t("index.placeholder1"));
				return;
			}
			this.loading = true;
			betCreate({
				quiz_id: this.item.id,
				red_amount: this.red ? this.chips : 0,
				blue_amount: this.blue ? this.chips : 0,
				type: this.red ? 1 : 2,
			})
				.then(({ code, message }) => {
					this.loading = false;
					this.$toast(message);
					if (code === 200) {
						this.show = false;
						this.red = false;
						this.blue = false;
						this.chips = "";
						this._quizList();
						this.fetchUser();
					}
				})
				.catch(() => {
					this.loading = false;
				});
		},
	},
	watch: {
		betTime(time) {
			this.time = time;
		},
	},
};
</script>

<style lang="less" scoped>
.welfare-banner {
	width: 100%;
	height: 290px;
	background: url("../../assets/welfare-top-bg.png") left -44px no-repeat;
	background-size: cover;
	overflow: hidden;
	.title {
		font-size: 18px;
		color: var(--font-color);
		text-align: center;
		margin: 15px auto;
	}
}
.popup-info {
	text-align: center;
	color: var(--font-color);
	> p {
		margin-bottom: 12px;
	}
}

.welfare-ships__btn {
	text-align: center;
	margin-top: 24px;
	/deep/ .van-button {
		font-size: 14px;
		color: #fff !important;
		border-radius: 7px 7px 7px 7px;
	}
}

.welfare-stepper {
	text-align: center;
	margin: 30px auto 0 auto;
	width: 215px;
	border-radius: 29px 29px 29px 29px;
	border: 1px solid #fc1157;
	overflow: hidden;
	box-sizing: border-box;
	.van-stepper {
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
	}
	/deep/ .van-stepper__minus {
		color: #fc1157;
		background: #242229;
		padding: 8px;
	}
	/deep/ .van-stepper__minus::before,
	/deep/ .van-stepper__plus::before {
		width: 30%;
		height: 1px;
	}

	/deep/ .van-stepper__plus::after {
		width: 1px;
		height: 30%;
	}
	/deep/ .van-stepper__input {
		background: #242229;
		color: #fff;
	}
	/deep/ .van-stepper__plus {
		color: #fc1157;
		background: #242229;
		padding: 8px;
	}
}

.popup-top__btn {
	display: flex;
	justify-content: center;
	padding: 24px 0;
}

.popup-top {
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup-top__text {
	position: absolute;
	top: 30px;
	font-size: 18px;
	color: #fff;
}

.popup-top__bg {
	width: 245px;
	height: 56px;
	display: block;
}
.Bet.van-popup--center {
	background: none;
	.icon-pk {
		width: 153.33px;
		height: 70px;
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 10;
	}
}
.popup-container {
	margin-top: -30px;
	width: 100%;
	// height: 100%;
	position: relative;
	background-color: #242229;
	border-radius: 16px 16px 16px 16px;
	overflow: hidden;
	.block-pl {
		width: 268px;
		height: 62px;
		margin: 0 auto;
		background: #131419;
		border-radius: 7px 7px 7px 7px;
		display: flex;
		align-items: center;
		.pl-item {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			color: #fff;
			p {
				width: 100%;
				text-align: center;
				color: #949494;
			}
		}
		.hr {
			width: 0;
			height: 100%;
			border: 1px solid #1a1a1a;
		}
	}
}

.welfare-btn {
	position: absolute;
	width: 120px;
	height: 38px;
	text-align: center;
	line-height: 38px;
	font-size: 17px;
	color: #fff;
}

.welfare-btns__pk {
	position: absolute;
	width: 92px;
}
.welfare-btns__pk-pc {
	display: none;
}

.welfare-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.pk-left-pc {
		display: none;
	}
	.pk-right-pc {
		display: none;
	}
	.btns-left-pc,
	.btns-right-pc {
		.van-ellipsis {
			display: none;
		}
	}
	> div {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
		}
	}
}

.welfare-content {
	display: flex;
	font-size: 13px;
	padding: 6px 0;

	> div {
		flex: 1;
		font-size: 13px;
		color: #1070f9;
		&:first-child {
			color: #e11e7a;
			margin-right: 12px;
		}
	}
}

.welfare-head {
	font-size: 13px;
	color: #fff;
	.welfare-head-time {
		font-size: 12px;
		color: #666666;
		margin-bottom: 6px;
	}
}

@font-face {
	font-family: YouSheBiaoTiHei;
	src: url("../../assets/font/优设标题黑.ttf");
}
.welfare-index {
	font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
	font-size: 18px;
	font-weight: 400;
	margin-right: 12px;
	background: linear-gradient(
		90deg,
		#fe77b0 0%,
		#f658c1 100%
	); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
	-webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
	-webkit-text-fill-color: transparent; /*给文字设置成透明*/
}

.welfare-card__container {
	padding: 0 12px;
}
.welfare {
	min-height: 100vh;
	background: url(../../assets/login_bg.png) left top var(--bg-color);
	background-size: 100%;
}
.time {
	width: 343px;
	min-height: 38px;
	margin: 6px auto 10px auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
	padding: 0 12px;
	background: var(--bg-color);
	border-radius: 10px 10px 10px 10px;
	line-height: 24px;
	color: var(--font-color);
	.welfare-balance-pc {
		display: none;
	}
	span {
		// white-space: nowrap;
	}
	span.fc-red {
		color: #ee0a24;
	}

	.van-count-down {
		display: inline-block;
		line-height: 1;
		color: #ee0a24;
		font-size: 16px;
	}

	> div {
		//flex: 1;
		//display: flex;
		//align-items: center;
		//flex-wrap: wrap;
		&:first-child {
			margin-right: 12px;
		}
	}
}
.qi-btn__container {
	display: flex;
	justify-content: center;
	margin-top: -200px;
}
.qi-btn {
	display: inline-flex;
	width: 202px;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
	border-radius: 20px;
	color: #fff;
	background: url(../../assets/welfare-qi-bg.png) left top no-repeat;
	background-size: cover;
	span {
		width: 100%;
		display: block;
		text-align: center;
	}
	.qi-text {
		flex-shrink: 0;
		width: 30%;
		color: #fc1157;
	}
}

.welfare-banner {
	display: block;

	img {
		width: 100%;
	}
}
.popup-main {
	width: 100%;
	.popup-title {
		margin: 10px auto;
		text-align: center;
		font-weight: normal;
	}
	.form-box {
		padding: 0 15px;
	}
	.input-pw {
		padding: 0;
		/deep/.van-field__body {
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			border: 1px solid #e5e5e5;
			border-radius: 6px;
		}
	}
	/deep/.input-pw.van-cell::after {
		border-bottom: 0;
	}
	.btn-box {
		margin: 15px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn {
			width: 45%;
		}
	}
}
.main {
	width: 343px;
	margin: 0 auto;
	background: var(--bg-color);
	border-radius: 16px 16px 0px 0px;
	.welfare-balance {
		padding: 0 12px;
		height: 42px;
		line-height: 42px;
		font-size: 14px;
		color: #fff;
		.icon {
			width: 16px;
			height: 16px;
			margin-right: 4px;
			vertical-align: middle;
		}
		span {
			font-weight: 600;
			font-size: 16px;
		}
	}
	.welfare-card {
		background: linear-gradient(180deg, #2f2923 0%, #191616 100%);
		border-radius: 14px 14px 14px 14px;
		box-shadow: none;
	}
}

@media only screen and (min-width: 750px) {
	.welfare-banner {
		height: 440px;
		background: url("../../assets/welfare-banner-pc.png") center top no-repeat;
		background-size: cover;
		.title {
			display: none;
		}
	}
	.qi-btn__container {
		margin-top: -190px;
		.qi-btn {
			width: 290px;
			height: 52px;
			line-height: 52px;
			font-size: 20px;
			border-radius: 26px;
		}
	}
	.time {
		width: fit-content;
		min-height: initial;
		height: 60px;
		border-radius: 18px 18px 18px 18px;
		margin: 26px auto 51px auto;
		justify-content: space-between;
		font-size: 18px;
		font-weight: 400;
		line-height: initial;
		padding: 0 20px;
		.welfare-balance-pc {
			display: block;
			.icon {
				vertical-align: middle;
			}
		}
		.van-count-down {
			font-size: 18px;
		}
		> div {
			white-space: nowrap;
			margin: 0 20px;
		}
	}
	.main {
		margin: 0 auto 30px auto;
		background: none;
		border-radius: 0;
		.welfare-balance {
			display: none;
		}
		.welfare-card {
			background: none;
			border-radius: 0;
			box-shadow: none;
			padding: 0;
			.welfare-head {
				font-size: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 20px;
				padding: 0 40px;
				.welfare-index {
					font-size: 24px;
					margin-right: 12px;
				}
				.welfare-head-time {
					font-size: 14px;
					margin-bottom: 0;
				}
			}
			.welfare-btns {
				display: flex;
				align-items: center;
				width: 100%;
				> div {
					width: initial;
					img {
						width: 100%;
					}
				}
				.welfare-btn {
					position: initial;
					width: 120px;
					height: 38px;
					text-align: center;
					line-height: 38px;
					font-size: 17px;
					color: #fff;
				}

				.welfare-btns__pk {
					display: none;
				}
				.welfare-btns__pk-pc {
					display: block;
					width: 173px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				.pk-left {
					display: none;
				}
				.pk-right {
					display: none;
				}
				.btns-left-pc,
				.btns-right-pc {
					width: 50%;
					height: 3.23vw;
					display: flex;
					align-items: center;
					justify-content: space-between;
					box-sizing: border-box;
				}
				.btns-left-pc {
					background: url(../../assets/pk-left-pc.png) center center no-repeat;
					background-size: cover;
					padding: 0 65px 0 45px;
					.welfare-btn {
						margin-left: 20px;
					}
					.fc-red {
						display: block;
						font-size: 16px;
						color: #ffffff;
						width: 60%;
					}
				}
				.btns-right-pc {
					background: url(../../assets/pk-right-pc.png) center center no-repeat;
					background-size: cover;
					padding: 0 45px 0 65px;
					.welfare-btn {
						margin-right: 20px;
					}
					.fc-blue {
						display: block;
						font-size: 16px;
						color: #ffffff;
					}
				}
			}
			.welfare-content {
				display: none;
			}
		}
	}
	.Bet.van-popup--center {
		width: 520px !important;
		min-height: 615px;
		.icon-pk {
			width: 173.17px;
			height: 79.06px;
		}
		.popup-container {
			margin-top: -40px;
			width: 100%;
			// height: 100%;
			position: relative;
			background: #242229;
			border-radius: 16px 16px 16px 16px;
			overflow: hidden;
			.block-pl {
				width: 350px;
				height: 70px;
				border-radius: 7px 7px 7px 7px;
				.pl-item {
					font-size: 18px;
					height: 100%;
					align-content: space-evenly;
					p {
						font-size: 16px;
					}
				}
			}
			.popup-top__btn {
				padding: 25px 0;
				.van-button--small {
					width: 128px !important;
					height: 40px;
					font-size: 18px;
					padding: 0 20px;
					border-radius: 7px 7px 7px 7px;
				}
			}

			.popup-top {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.popup-info {
				font-size: 16px;
			}
			.popup-top__text {
				position: absolute;
				top: 30px;
				font-size: 18px;
			}

			.popup-top__bg {
				width: 245px;
				height: 56px;
				display: block;
			}
			.welfare-stepper {
				margin: 22px auto 0 auto;
				width: 285px;
				height: 42px;
				.van-stepper {
					width: 100%;
					height: 42px;
					font-size: 18px;
					/deep/ .van-stepper__minus {
						padding: 0;
					}
					/deep/ .van-stepper__plus {
						padding: 0;
					}
				}
				/deep/ .van-stepper__input {
					font-size: 16px;
				}
			}
			.welfare-ships__btn {
				margin-top: 24px;
				/deep/ .van-button {
					width: 90px;
					height: 38px;
					font-size: 16px;
					color: #fff !important;
					border-radius: 7px 7px 7px 7px;
					padding: 0 10px;
				}
			}
			.px-24 {
				.submit-btn {
					margin: 0 auto;
					width: 350px;
					height: 48px;
					border-radius: 25px 25px 25px 25px;
					font-size: 15px;
				}
			}
		}
	}
	.password {
		width: 520px !important;
		border-radius: 20px;
	}
	.popup-main {
		.popup-title {
			margin: 30px auto;
			font-size: 30px;
		}
		.form-box {
			padding: 0 15px;
		}
		.input-pw {
			padding: 0;
			/deep/.van-field__body {
				height: 56px;
				line-height: 56px;
				padding: 0 10px;
				border: 1px solid #e5e5e5;
				border-radius: 15px;
				font-size: 16px;
			}
			/deep/.van-field__error-message {
				font-size: 16px;
				line-height: 56px;
			}
		}
		.input-pw.van-cell::after {
			border-bottom: 0;
		}
		.btn-box {
			margin: 30px auto;
			.btn {
				height: 56px;
				font-size: 18px;
			}
		}
	}
}
</style>
